import NotFoundPage from '@/views/notFound';
import Home from '@/views/home';
import Result from '@/views/result';
import Aboutus from '@/views/aboutus';
import MHome from '@/views/m/home';
import MResult from '@/views/m/result';
import MAboutus from '@/views/m/aboutus';
import MDownloadpage from '@/views/m/downloadPage';

const router = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/result',
    element: <Result />,
  },
  {
    path: '/aboutus',
    element: <Aboutus />,
  },
  {
    path: '/m',
    children: [
      {
        path: '/m/home',
        element: <MHome />,
      },
      {
        path: '/m/result',
        element: <MResult />,
      },
      {
        path: '/m/aboutus',
        element: <MAboutus />,
      },
      {
        path: '/m/downloadpage',
        element: <MDownloadpage />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export default router;
