import React, { useEffect, useRef, useState } from 'react';
import { Carousel } from 'antd';
import Header from '@/views/header';
import Footer from '@/views/footer';
import oneDImg from '@/assets/home/result/交通运输1@2x.png';
import oneHImg from '@/assets/home/result/交通运输2@2x.png';
import twoDImg from '@/assets/home/result/石油1.png';
import twoHImg from '@/assets/home/result/石油石化1@2x.png';
import threeDImg from '@/assets/home/result/电力通讯1@2x.png';
import threeHImg from '@/assets/home/result/电力通讯2.png';
import fourDImg from '@/assets/home/result/危险作业1@2x.png';
import fourHImg from '@/assets/home/result/危险作业2@2x.png';
import fiveDImg from '@/assets/home/result/航天军工1@2x.png';
import fiveHImg from '@/assets/home/result/航天军工2@2x.png';
import sixDImg from '@/assets/home/result/仓储安全1@2x.png';
import sixHImg from '@/assets/home/result/仓储安全2@2x.png';
import smallOne from '@/assets/home/result/one.png';
import smallTwo from '@/assets/home/result/two.png';
import smallThree from '@/assets/home/result/three.png';
import smallFour from '@/assets/home/result/four.png';
import smallFive from '@/assets/home/result/five.png';
import smallSix from '@/assets/home/result/six.png';
import leftBtn from '@/assets/home/result/left.png';
import rightBtn from '@/assets/home/result/rig.png';
import toneImg from '@/assets/home/tedian/智能报警图@2x.png';
import ttwoImg from '@/assets/home/tedian/重复使用图@2x.png';
import tthreeImg from '@/assets/home/tedian/一机多锁图@2x.png';
import tfourImg from '@/assets/home/tedian/防水防爆图@2x.png';
import tfiveImg from '@/assets/home/tedian/远程授权图@2x.png';
import tsixImg from '@/assets/home/tedian/责任追溯图@2x.png';
import jmOne from '@/assets/home/Group@2x.png';
import jmTwo from '@/assets/home/jmtwo.png';
import jmThree from '@/assets/home/jmthree.png';
import jmFour from '@/assets/home/jmfour.png';
import dapingImg from '@/assets/home/daping.png';
import bannerImg1 from '@/assets/home/result/four1.jpg';
import bannerImg2 from '@/assets/home/result/four2.jpg';
import bannerImg3 from '@/assets/home/result/four3.jpg';
import bannerImg4 from '@/assets/home/result/four4.jpg';
import bannerImg5 from '@/assets/home/result/four5.jpg';

import './index.css';

const AccordionItem = ({ data, index, isActive, onHover }) => {
  return (
    <div
      className={`accordion-item ${isActive ? 'active' : ''}`}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={() => onHover(null)}
    >
      <div
        className="default-image"
        style={{ backgroundImage: `url(${data.defaultImg})` }}
      >
        <div className="d-title-ctn">
          <div className="d-enTitle">{data.enTitle}</div>
          <div className="d-cnTitle">{data.cnTitle}</div>
        </div>
        <img src={data.smallImg} className="smallIcon" />
      </div>

      <div
        className="hover-image"
        style={{ backgroundImage: `url(${data.hoverImg})` }}
      >
        <div className="title-ctn">
          <div className="enTitle">{data.enTitle}</div>
          <div className="cnTitle">{data.cnTitle}</div>
        </div>
        <div
          className="ctn-desc"
          dangerouslySetInnerHTML={{ __html: data.desc }}
        ></div>
        <img src={data.smallImg} className="smallIcon" />
      </div>
    </div>
  );
};

const Reult = () => {
  const homeTwoRef = useRef(null);
  const homeThreeRef = useRef(null);
  const [twoTopHeight, setTwoTopHeight] = useState(0);
  const [threeTopHeight, setThreeTopHeight] = useState(0);
  const [animate, setAnimate] = useState(true);
  const [threeAnimate, setThreeAnimate] = useState(false);

  const [activeIndex, setActiveIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);

  const [sfqData, setSfqData] = useState([
    {
      defaultImg: oneDImg,
      hoverImg: oneHImg,
      enTitle: 'Transportation industry',
      cnTitle: '交通运输行业',
      desc: '在铁路、航空、远洋、公路运输过程中对集装箱、包装柜、重点设备等均需要使用锁具进行安全保障。',
      smallImg: smallOne,
    },
    {
      defaultImg: twoDImg,
      hoverImg: twoHImg,
      enTitle: 'Oil and petrochemical industry',
      cnTitle: '石油石化行业',
      desc: '在油田生产管理中，通过锁具对重点要害部位挂牌上锁。如输气输油管线阀门、控制柜、计量仪表校验、重点测、钻、采设备的管理。<br/> 对无人值守场站、机房、电力设备的防盗防拆以及油气输运环节中的防盗措施，均需要使用具 有无线报警功能的施封锁，确保正常运行。',
      smallImg: smallTwo,
    },
    {
      defaultImg: threeDImg,
      hoverImg: threeHImg,
      enTitle: 'Power and telecommunications',
      cnTitle: '电力通讯行业',
      desc: '无人值守输变电站、无人值守通讯基站数量庞大。在传统防盗防破坏的安全需求之外，远程授权管理开锁功能将使生产管理更加便捷。',
      smallImg: smallThree,
    },
    {
      defaultImg: fourDImg,
      hoverImg: fourHImg,
      enTitle: 'Hazardous operations sector',
      cnTitle: '危险作业领域',
      desc: '各类施工作业现场，需要关断各类阀门或电闸才能具备施工条件，这些阀闸在施工过程中是绝对禁止开启的。使用具有无线报警功能的施封锁，能够进一步加强现场管理，一旦有违规开启发生即可无线预警，避免安全事故的发生。',
      smallImg: smallFour,
    },
    {
      defaultImg: fiveDImg,
      hoverImg: fiveHImg,
      enTitle: 'Aerospace and military industry',
      cnTitle: '航天军工领域',
      desc: '针对航天、军工、军队等保密级别较高的场所，锁具既要确保其严格的保密性，又要确保在充分授权后的及时开启，尤其在武器库等重点保障场所，多人授权开锁管理要求等级高具有无线报警功能的施封锁能够高效、安全的发挥辅助管理作用。',
      smallImg: smallFive,
    },
    {
      defaultImg: sixDImg,
      hoverImg: sixHImg,
      enTitle: 'Warehouse safety',
      cnTitle: '仓储安全',
      desc: '通过智能报警施封锁管控库房，避免非授权人员进入库房。一旦锁具遭到破坏立即发出报警信息，保障物资安全。对于重要物资库房，实行授权开锁模式。库房需要多人授权才能开启，确保重要物资安全。',
      smallImg: smallSix,
    },
  ]);

  const [chanpinData, setChanpinData] = useState([
    {
      id: 1,
      data: '智能报警',
      check: true,
      imgSrc: toneImg,
      title: '智能报警',
      desc: '★锁具非法开启：剪断锁绳、暴力破坏锁体，锁具立即发送报警信息到监控中心及对应锁具管理人的App。 <br /> ★电量低于阔值、温度过高或过低，向监控中心和对应锁具管理人手机App发送报警信息。<br />★定时向监控中心发送锁的日报（电量、状态等）便于及时掌握锁的状况。',
    },
    {
      id: 2,
      data: '重复使用',
      check: false,
      imgSrc: ttwoImg,
      title: '重复使用',
      desc: '智能报警施封锁通过蓝牙与鸿凯智锁App（手机/手持终端）加密通讯，接收开锁、关锁等指令操作，使锁具可以重复使用，减少资源的浪费。',
    },
    {
      id: 3,
      data: '一机多锁',
      check: false,
      imgSrc: tthreeImg,
      title: '一机多锁',
      desc: '开锁不用钥匙，手机APP可以完成一个手机管理多个锁。解决了丢失（忘带)钥匙和出门带多个钥匙或卡的弊端。',
    },
    {
      id: 4,
      data: '防水防爆',
      check: false,
      imgSrc: tfourImg,
      title: '防水防爆',
      desc: '智能报警施封锁采用全密封式设计，防拆，具备IP65防护等级。智能锁按照危险环境防爆电器规范设计要求通过了防爆等认证，防爆等级为：本安Ex ib IIC T3 Gb。同时智能锁依照军用设备检测标准进行了检测，并通过了国际互认的CNAS认证。',
    },
    {
      id: 5,
      data: '多级授权&远程授权',
      check: false,
      imgSrc: tfiveImg,
      title: '远程授权&多级授权',
      desc: '开锁、关锁功能只有相应权限的App用户才能操作，对于临时没有权限的用户，管理员可以远程授权其开关锁权限。同时系统具有多级授权管理，可对一些重要场合设置多人授权开关锁操作，只有多级授权的所有人都同意开关锁，则该锁才能正常开关。',
    },
    {
      id: 6,
      data: '责任追溯',
      check: false,
      imgSrc: tsixImg,
      title: '责任追溯',
      desc: '系统对开锁、关锁、报警处理、更换锁、故障处理等所有相关的操作进行记录，并保存到云服务器，便于查看智能锁使用状态、责任追溯等。记录内容包括日期时间、操作人、位置、操作类型、照片等详细信息。',
    },
    { id: 7, data: '高级加密&精巧结构 <br /> 磁吸充电&低功耗', check: false },
    { id: 8, data: '数据可视化监测大屏', check: false },
  ]);
  const [tedianCheckId, setTedianCheckId] = useState(1);
  const intervalRef = useRef(null); // 用于存储动态定时器
  const timeoutRef = useRef(null); // 用于存储点击后的延迟定时器

  // 动态切换功能
  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setTedianCheckId((prevId) => {
        const nextId = prevId >= chanpinData.length ? 1 : prevId + 1;
        setChanpinData((prevData) =>
          prevData.map((item) =>
            item.id === nextId
              ? { ...item, check: true }
              : { ...item, check: false }
          )
        );
        return nextId;
      });
    }, 8000); // 每隔 5 秒切换一次
  };

  // 清除动态定时器
  const clearIntervalEffect = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  // 清除点击后的延迟定时器
  const clearTimeoutEffect = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  // 选中功能
  const handSelectChanpin = (data) => {
    clearIntervalEffect(); // 停止动态切换
    clearTimeoutEffect(); // 停止当前延迟逻辑

    // 更新选中状态
    const newData = chanpinData.map((reward) => ({
      ...reward,
      check: reward.id === data.id,
    }));
    setChanpinData(newData);
    setTedianCheckId(data.id);

    // 在 8 秒后重新开始动态效果
    timeoutRef.current = setTimeout(() => {
      startInterval();
    }, 8000);
  };

  // 初始化动态定时器
  useEffect(() => {
    startInterval();
    return () => {
      // 清理所有定时器，防止内存泄漏
      clearIntervalEffect();
      clearTimeoutEffect();
    };
  }, [chanpinData.length]);

  const handleHover = (index) => {
    setActiveIndex(index !== null ? index : startIndex);
  };

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % sfqData.length);
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0 ? sfqData.length - 4 : prevIndex - 1
    );
  };

  useEffect(() => {
    document.title = '鸿凯瑞达-产品';
    const { top } = homeTwoRef.current.getBoundingClientRect();
    setTwoTopHeight(top);
    const threeRef = homeTwoRef.current.getBoundingClientRect();
    setThreeTopHeight(threeRef.top);

    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad';
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os';
    var bIsMidp = sUserAgent.match(/midp/i) == 'midp';
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
    var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb';
    var bIsAndroid = sUserAgent.match(/android/i) == 'android';
    var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
    var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      window.location.replace('/m/result');
    }
  }, []);

  return (
    <div>
      <div className="home-one">
        <Header pageName="result" />
        <div className="bg-top-img">
          <div className="top-img-text">
            <div className="en-title">Intelligent alarm sealing lock</div>
            <div className="cn-title">智能报警施封锁</div>
          </div>
          <div className="top-center-text">
            “鸿凯瑞达智能报警施封锁“是一款具有智能报警且可反复使用的施封锁，他解决了传统施封锁不能重复使用，不能及时获知
            锁具的状态，非法损坏不能及时知晓，无法进行多级授权开锁，无法确认操作人身份工作过程无法跟踪等弊端，是一款安全性极高的产品。
          </div>
          <div className="zlh">专利号：ZL202121275780.2</div>
        </div>
      </div>
      <div className="home-two-result">
        <div className="img-ctn" ref={homeTwoRef}>
          <div className="right-ctn-four">
            <div className="en-title">Technical parameters</div>
            <div className="title">智能报警施封锁技术参数</div>
            <div className="title-line"></div>
            <div className="ctn">
              产品采用全密封式设计，不锈钢材质具有出色的强度，具备IP65防护等级。智能锁按照危险环境防爆电器规范设计要求通过了防爆等认证，防爆等级为：本安Ex
              ibⅡIC T3 Gb。
            </div>
            <div className="ctn" style={{ marginTop: 3 }}>
              同时智能锁依照军用设备检测标准进行了检测，并通过了国际互认的CNAS认证，确保在多种应用场景下的卓越表现。无论是日常使用还是专业需求，本产品都能满足您的期望
            </div>
            <div className="disflex">
              <div className="result-js"></div>
            </div>
          </div>
          <div className="bottom-text">
            <div className="one-title">方案技术简介</div>
            <div className="content">
              针对原有的施封锁功能，进行了技术革新。颠覆了传统施封锁的使用机制，实现了重复使用、智能报警、多级管理等诸多创新功能，可应用到石油石化、电力通信、交通运输、食品药品、航天军工、文物与档案保护、司法封存、仓储安全、危险作业等行业和领域。
            </div>
          </div>
        </div>
      </div>
      <div className="home-three-result">
        <div className="en-title">Application field</div>
        <div className="title">多个应用领域</div>
        <div className="title-line"></div>

        {startIndex !== 0 && (
          <img src={leftBtn} className="leftBtn" onClick={handlePrev} />
        )}
        <div className="accordion">
          {sfqData.slice(startIndex, startIndex + 4).map((reward, index) => (
            <AccordionItem
              key={index}
              index={index}
              isActive={activeIndex === index}
              onHover={handleHover}
              data={reward}
            />
          ))}
        </div>
        {startIndex < sfqData.length - 4 && (
          <img src={rightBtn} className="rightBtn" onClick={handleNext} />
        )}

        <div className="en-title" style={{ marginTop: 80 }}>
          Product features
        </div>
        <div className="title">产品特点</div>
        <div className="title-line"></div>

        <div className="cp-view">
          <div className="cp-left">
            <div className="leftLine"></div>
            <div className="rightText">
              {chanpinData?.map((reward) => {
                return (
                  <div
                    className={`${reward.check ? 'check' : ''}`}
                    key={reward.id}
                  >
                    <div
                      className="textStyle"
                      dangerouslySetInnerHTML={{ __html: reward.data }}
                      style={
                        reward.check
                          ? { color: '#061745', fontWeight: 'bold' }
                          : null
                      }
                      onClick={() => handSelectChanpin(reward)}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
          {chanpinData.map((reward) => {
            if (reward.id === tedianCheckId) {
              if (reward.id === 7) {
                return (
                  <div
                    className="animate__animated animate__backInRight"
                    style={{ marginLeft: '5vw', marginTop: '7vh' }}
                  >
                    <div className="jm-card">
                      <div>
                        <div className="title">高级加密</div>
                        <div className="desc">
                          锁具通信采用动态密钥+AES128加密+自研加密算法多重加密，使其安全性得到保障。
                        </div>
                      </div>
                      <img src={jmOne} className="imgstyle" />
                    </div>
                    <div className="jm-card">
                      <div>
                        <div className="title">精巧结构</div>
                        <div className="desc">
                          智能锁内部采用巧妙的机械及动力设计，在极小的空间里也能完成开关锁和报警检测，并且锁具闭锁能够支撑2KN拉力。结构、和控制系统分别已经申请了发明专利。
                        </div>
                      </div>
                      <img src={jmTwo} className="imgstyle" />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div
                        className="jm-card"
                        style={{ width: '48%', display: 'block' }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div className="title">磁吸充电</div>
                          <img src={jmThree} className="imgstyle" />
                        </div>

                        <div className="desc">
                          便捷、稳定，防水、耐用，内部保护电路使其安全可靠。电源接头采用标准的USB-A口，方便用户使用。
                        </div>
                      </div>
                      <div
                        className="jm-card"
                        style={{ width: '48%', display: 'block' }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <div className="title">低功耗</div>
                          <img src={jmFour} className="imgstyle" />
                        </div>
                        <div className="desc">
                          智能报警施封锁待机时间最长达120天，满足野外无人值守地点使用。
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
              if (reward.id === 8) {
                return (
                  <div
                    className="animate__animated animate__backInRight"
                    style={{ marginLeft: '5vw', marginTop: '-7vh' }}
                  >
                    <div className="daping">
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <img src={dapingImg} className="dapingImg" />
                      </div>
                      <div className="title">数据可视化监测大屏</div>
                      <div className="desc">
                        数据可视化监测大屏可以实时展示智能报警施封锁的运行状态和使用情况。实时展示报警提醒、开关锁操作的记录和设备使用频次的统计分析可，以及使用中锁具状态的可视化。极大提升管理效率，保障物资安全，并支持决策制定。
                      </div>
                    </div>
                  </div>
                );
              }
              return (
                <div className="animate__animated animate__backInRight">
                  <img src={reward.imgSrc} className="rightImg" />
                  <div className="rightBottomText">
                    <div className="title">{reward.title}</div>
                    <div
                      className="desc"
                      dangerouslySetInnerHTML={{ __html: reward.desc }}
                    ></div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="home-four-result">
        <div className="en-title">Areas of use</div>
        <div className="title">应用场景</div>
        <div className="title-line"></div>
        <Carousel
          infinite
          autoplay
          className="bannerImg"
          arrows={true}
          prevArrow={<img src={leftBtn} />}
          nextArrow={<img src={rightBtn} />}
        >
          <img src={bannerImg1} />
          <img src={bannerImg2} />
          <img src={bannerImg3} />
          <img src={bannerImg4} />
          <img src={bannerImg5} />
        </Carousel>
      </div>
      <Footer />
    </div>
  );
};

export default Reult;
