import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FloatingBubble } from 'antd-mobile';
import { DownlandOutline } from 'antd-mobile-icons';
import downloadicon from '@/assets/m/downloadicon.png';

const DownlandBtn = () => {
  const navigate = useNavigate();
  const goDownloadPage = () => {
    navigate('/m/downloadpage');
  };
  return (
    <FloatingBubble
      axis="xy"
      magnetic="x"
      style={{
        '--initial-position-bottom': '30vh',
        '--initial-position-right': '10px',
        '--edge-distance': '16px',
      }}
      onClick={() => goDownloadPage()}
    >
      <img
        src={downloadicon}
        alt=""
        style={{ width: '22px', height: '22px' }}
      />
    </FloatingBubble>
  );
};

export default DownlandBtn;
