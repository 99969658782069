import React, { useEffect, useState } from 'react';
import logoImg from '@/assets/m/icon@2x.png';
import androidImg from '@/assets/m/android.png';
import './index.css';

const DownloadPage = (props) => {
  useEffect(() => {
    document.title = '鸿凯瑞达-App下载';
  }, []);

  const goDownLoadApp = () => {
    // https://www.pgyer.com/app/install/3fa3e081d15454bfe15f9307e748fe72?time=1711694867010&lang=cn
    const link = document.createElement('a');
    link.href = `http://www.hongkairuida.com/%E6%96%BD%E5%B0%81%E9%94%81%E7%AE%A1%E7%90%86APP_1.0.0.apk`; // 替换为你的APK文件路径
    link.download = '施封锁管理APP.apk'; // 下载时保存的文件名
    link.click();
  };
  return (
    <div className="page-all">
      <div className="dow-page">
        <img src={logoImg} alt="" className="logoimg" />
        <div className="logoname">施封锁管理APP</div>
        <div className="logotips">轻量化管理您的设备</div>

        <div className="btm-dowbtn" onClick={() => goDownLoadApp()}>
          <img src={androidImg} alt="" />
          <div>Android 下载</div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
