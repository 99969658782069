import { useRoutes } from 'react-router-dom';

import router from '@/router';
import 'animate.css';

import './App.css';

const App = () => {
  const route = useRoutes(router);
  return (
    <>
      {/* <Flex gap="small" align="flex-start" vertical={false}>
        <Button type="primary">
          <Link to="/">首页</Link>
        </Button>
        <Button type="primary">
          <Link to="/about">关于</Link>
        </Button>
      </Flex>
      <Divider orientation="left">正文</Divider> */}
      <div>{route}</div>
    </>
  );
};

export default App;
