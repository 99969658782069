import React, { useEffect, useRef, useState } from 'react';
import Header from '@/views/header';
import { Image } from 'antd';
import Footer from '@/views/footer';
import OneRImg from '@/assets/home/aboutus/one.png';
import sanimg from '@/assets/home/aboutus/san.png';
import rtwoimg from '@/assets/home/aboutus/twor.png';
import bg1img from '@/assets/home/aboutus/bg1.png';
import bg2img from '@/assets/home/aboutus/bg2.png';
import bg3img from '@/assets/home/aboutus/bg3.png';
import bg4img from '@/assets/home/aboutus/bg4.png';
import bg5img from '@/assets/home/aboutus/bg5.jpg';
import bg6img from '@/assets/home/aboutus/shu1.jpg';
import bg7img from '@/assets/home/aboutus/shu2.jpg';
import bg8img from '@/assets/home/aboutus/shu3.jpg';
import endImg from '@/assets/home/aboutus/endimg.png';
import photo1 from '@/assets/home/aboutus/Frame48@2x.jpg';
import photo2 from '@/assets/home/aboutus/Frame49@2x.jpg';
import photo3 from '@/assets/home/aboutus/Frame50@2x.jpg';
import photo4 from '@/assets/home/aboutus/Frame51@2x.jpg';
import photo5 from '@/assets/home/aboutus/Frame52@2x.jpg';

import './index.css';

const photoList = [photo1, photo2, photo3, photo4, photo5];
const Aboutus = () => {
  const homeTwoRef = useRef(null);
  const logoRef = useRef(null);
  const [twoTopHeight, setTwoTopHeight] = useState(0);
  const [logoTopHeight, setLogoTopHeight] = useState(0);
  const [animate, setAnimate] = useState(false);
  const [logoAnimate, setLogoAnimate] = useState(false);

  useEffect(() => {
    document.title = '鸿凯瑞达-关于我们';
    // const { top } = homeTwoRef.current.getBoundingClientRect();
    // setTwoTopHeight(top);
    // const logoRefValus = logoRef.current.getBoundingClientRect();
    // setLogoTopHeight(logoRefValus.top);

    var sUserAgent = navigator.userAgent.toLowerCase();
    var bIsIpad = sUserAgent.match(/ipad/i) == 'ipad';
    var bIsIphoneOs = sUserAgent.match(/iphone os/i) == 'iphone os';
    var bIsMidp = sUserAgent.match(/midp/i) == 'midp';
    var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == 'rv:1.2.3.4';
    var bIsUc = sUserAgent.match(/ucweb/i) == 'ucweb';
    var bIsAndroid = sUserAgent.match(/android/i) == 'android';
    var bIsCE = sUserAgent.match(/windows ce/i) == 'windows ce';
    var bIsWM = sUserAgent.match(/windows mobile/i) == 'windows mobile';
    if (
      bIsIpad ||
      bIsIphoneOs ||
      bIsMidp ||
      bIsUc7 ||
      bIsUc ||
      bIsAndroid ||
      bIsCE ||
      bIsWM
    ) {
      window.location.replace('/m/aboutus');
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollDistance = window.scrollY;

      if (scrollDistance >= twoTopHeight) {
        // 滚动距离达到了指定值，设置状态以触发动画
        setAnimate(true);
      } else {
        // 如果滚动距离小于阈值，重置状态
        setAnimate(false);
      }

      if (scrollDistance >= logoTopHeight) {
        setLogoAnimate(true);
      }
    };

    // 添加滚动事件监听器
    window.addEventListener('scroll', handleScroll);

    // 组件卸载时移除监听器
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <Header pageName="aboutus" />
      <div className="about-bg-top-img">
        <div className="en-text">
          Xi'an Hongkairuida intelligence technology Co.,Ltd
        </div>
        <div className="imgs-text">西安鸿凯瑞达智能电子科技有限公司</div>
      </div>
      <div className="about-bg-two-img">
        <div className="title">智驭安全·护航未来</div>
        <div className="desc-one">
          西安鸿凯瑞达智能电子科技有限公司，致力于智能电子产品的研发。目前我们已开发了多款基于NB/LORA/4G/NFC/zigbee/蓝牙/超声波等的智能电子产品。最新研发的专利产品“智能报警施封锁”，颠覆了传统施封锁的使用机制，实现了重复使用、智能报警、多级管理等诸多创新功能，可应用到石油石化、电力通信等多个领域。
        </div>
        <div className="desc-two">
          公司团队拥有丰富的产业背景和研发经验同时也和多所国内一流大学科研交流合作，利用先进的技术和创新的思维，为客户提供高品质、高性能的产品。
        </div>
      </div>
      <div className="about-bg-three-img">
        <div className="en-title">Faith in Innovation</div>
        <div className="title">创新成就信仰</div>
        <div className="title-line"></div>

        <div className="btm-text">
          我们秉承“客户至上、追求卓越”的公司宗旨，本着“开拓、拼搏、求实、创新”的企业精神，“以诚信为本、以质量求生存、以创新为动力，为客户创造价值”的企业经营理念为客户服务。
        </div>

        <div className="three-quan">
          <div className="left-content">
            <div className="cn-name">革新</div>
            <div className="en-name">INNOVATE</div>
          </div>
          <div className="center-content">
            <div className="cn-name">智创</div>
            <div className="en-name">INGENUITY</div>
          </div>
          <div className="right-content">
            <div className="cn-name">绿色</div>
            <div className="en-name">GREENER</div>
          </div>
        </div>

        <div className="en-title">Honors reflect excellence</div>
        <div className="title">荣誉见证卓越</div>
        <div className="title-line"></div>

        <div className="btm-text">
          通过客户导向的核心理念、追求卓越的战略追求、创新驱动的发展战略以及诚信与质量并重的战略基石，共同构建起公司独特的竞争优势与可持续发展能力为客户创造更大价值，实现企业的长期繁荣与社会责任的共同担当。{' '}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            paddingLeft: '8%',
            paddingRight: '8%',
            marginTop: 80,
          }}
        >
          <div className="h-card">
            <Image width={200} src={OneRImg} />
            <div className="bt-ctnss">
              <div className="top-title">一种智能无线报警施封锁</div>
              <div style={{ marginTop: 15 }}>
                <img src={sanimg} className="sanimg" />
                <span className="zlihao">专利号：ZL 202121275780.2</span>
              </div>
            </div>
          </div>
          <div className="h-card">
            <Image width={200} src={rtwoimg} />
            <div className="bt-ctnss">
              <div className="top-title">一种机械设备安全距离无线报警装置</div>
              <div style={{ marginTop: 15 }}>
                <img src={sanimg} className="sanimg" />
                <span className="zlihao">专利号：ZL 202121428038.0</span>
              </div>
            </div>
          </div>
        </div>

        <div className="h-card-two">
          <div className="title-ctn">
            <div className="en-title">Certificates and reports</div>
            <div className="cn-title">各类证书报告</div>
          </div>
          <div className="baogao-view">
            <Image width={200} src={bg1img} />
            <Image width={200} src={bg2img} />
            <Image width={200} src={bg3img} />
            <Image width={200} src={bg4img} />
            <Image width={200} src={bg5img} />
            <Image width={200} src={bg6img} />
            <Image width={200} src={bg7img} />
            <Image width={200} src={bg8img} />
          </div>
        </div>

        <div className="en-title" style={{ marginTop: 60 }}>
          Innovation Leads to Shared Success
        </div>
        <div className="title">智创共赢未来</div>
        <div className="title-line"></div>

        <div class="wrapper">
          {photoList.map((reward, index) => (
            <img
              src={reward}
              className="item"
              style={{ '--count': index + 1 }}
            />
          ))}
        </div>
      </div>
      <div className="about-bg-four">
        <div className="leftFour">
          <div className="en-title">Our goals</div>
          <div className="title">我们的目标</div>
          <div className="title-line"></div>

          <div className="border-div">
            <div className="center-text">让每个人轻松开启数字新可能</div>
            <div className="lefttop">脚踏实地</div>
            <div className="righttop">坚毅敬业</div>
            <div className="leftbottom">求真务实</div>
            <div className="rightbottom">开拓创新</div>
          </div>
        </div>
        <img src={endImg} className="endimg" />
      </div>

      <Footer />
    </div>
  );
};

export default Aboutus;
