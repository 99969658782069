import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import logoImg from '@/assets/home/logo.png';
import logoWhiteImg from '@/assets/home/logo-white2.png';
import { MenuOutlined } from '@ant-design/icons';
import './index.css';

const Header = (props) => {
  const pageName = props.pageName;
  const navigate = useNavigate();
  const [menuShow, setMenuShow] = useState(false);
  const menuRef = useRef(null);
  const menuIconRef = useRef(null);
  const goPage = (path) => {
    navigate(path);
    setMenuShow(false);
  };

  const showMenu = () => {
    setMenuShow(!menuShow);
  };

  const handleOutsideClick = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      !menuIconRef.current.contains(event.target)
    ) {
      setMenuShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div className="mTopTabs">
        <div className="top-flex">
          <img
            alt=""
            src={pageName === 'home' ? logoImg : logoWhiteImg}
            className="top-logo"
          />
          <div
            className="logo-name"
            style={pageName === 'home' ? {} : { color: '#ffffff' }}
          >
            鸿凯瑞达
          </div>
        </div>
        <div style={{ marginTop: '3vh' }}>
          <MenuOutlined
            style={{
              fontSize: '1.5rem',
              color: pageName === 'home' ? '#1549D0' : '#ffffff',
              marginRight: '4vw',
            }}
            onClick={() => showMenu()}
            ref={menuIconRef}
          />
        </div>
      </div>
      <div
        ref={menuRef}
        className={`menu-div ${!menuShow ? 'menu-div-hidden' : ''}`}
      >
        <div
          className="active"
          style={pageName === 'home' ? { color: '#1549D0' } : {}}
          onClick={() => goPage('/m/home')}
        >
          首页
        </div>
        <div
          className="active"
          style={pageName === 'result' ? { color: '#1549D0' } : {}}
          onClick={() => goPage('/m/result')}
        >
          产品
        </div>
        <div
          className="active"
          style={pageName === 'aboutus' ? { color: '#1549D0' } : {}}
          onClick={() => goPage('/m/aboutus')}
        >
          关于我们
        </div>
      </div>
    </>
  );
};

export default Header;
