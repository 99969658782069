import React from 'react';
import appImg from '@/assets/home/aboutus/app-download.jpg';
import wxImg from '@/assets/home/aboutus/wx.jpg';
import './index.css';

const Header = () => {
  return (
    <div className="bttom-ctn">
      <div>
        <div>
          <span style={{ marginLeft: 0 }}>
            版权所有©西安鸿凯瑞达智能电子科技有限公司
          </span>
          <span>TEL：029-85223533 17791360109</span>
        </div>
        <div>地址：陕西省西安市曲江新区雁展路6号曲江会展国际E栋701室</div>
        <div
          style={{ marginBottom: 0, cursor: 'pointer' }}
          onClick={() => window.open('https://beian.miit.gov.cn/')}
        >
          陕ICP备2024027996号-1
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: 0, marginLeft: 130 }}>
        <div className="img-ctn">
          <img src={wxImg} className="imgStyle" />
          <div className="imgText">公众号</div>
        </div>
        <div className="img-ctn">
          <img src={appImg} className="imgStyle" />
          <div className="imgText">APP下载</div>
        </div>
      </div>
    </div>
  );
};

export default Header;
