import React from 'react';
import { useNavigate } from 'react-router-dom';
import logoImg from '@/assets/home/logo.png';
import logoWhiteImg from '@/assets/home/logo-white2.png';
import './index.css';

const Header = (props) => {
  const pageName = props.pageName;
  const navigate = useNavigate();

  const goPage = (path) => {
    navigate(path);
  };
  return (
    <div className="top-tabs">
      <div className="top-flex">
        <img
          alt=""
          src={pageName === 'home' ? logoImg : logoWhiteImg}
          className="top-logo"
        />
        <div
          className="logo-name"
          style={pageName === 'home' ? {} : { color: '#ffffff' }}
        >
          鸿凯瑞达
        </div>
      </div>
      <div
        className={pageName === 'home' ? 'active' : ''}
        onClick={() => goPage('/')}
      >
        首页
      </div>
      <div
        className={
          pageName === 'result'
            ? 'active'
            : pageName === 'home'
            ? 'divBlack'
            : ''
        }
        onClick={() => goPage('/result')}
      >
        产品
      </div>
      <div
        className={
          pageName === 'aboutus'
            ? 'active'
            : pageName === 'home'
            ? 'divBlack'
            : ''
        }
        onClick={() => goPage('/aboutus')}
      >
        关于我们
      </div>
    </div>
  );
};

export default Header;
