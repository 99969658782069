import React, { useEffect } from 'react';
import bgImg from '@/assets/images/image-404.png';

const NotFoundPage = () => {
  useEffect(() => {
    document.title = '404';
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={bgImg} alt="" />
    </div>
  );
};

export default NotFoundPage;
