import React, { useEffect, useRef, useState } from 'react';
import Header from '@/views/m/header';
import Footer from '@/views/m/footer';
import { Image } from 'antd';
import DownloadBtn from '@/views/m/downloadBtn';
import OneRImg from '@/assets/home/aboutus/one.png';
import sanimg from '@/assets/home/aboutus/san.png';
import rtwoimg from '@/assets/home/aboutus/twor.png';
import bg1img from '@/assets/home/aboutus/bg1.png';
import bg2img from '@/assets/home/aboutus/bg2.png';
import bg3img from '@/assets/home/aboutus/bg3.png';
import bg4img from '@/assets/home/aboutus/bg4.png';
import bg5img from '@/assets/home/aboutus/bg5.jpg';
import bg6img from '@/assets/home/aboutus/shu1.jpg';
import bg7img from '@/assets/home/aboutus/shu2.jpg';
import bg8img from '@/assets/home/aboutus/shu3.jpg';
import photo1 from '@/assets/home/aboutus/Frame48@2x.jpg';
import photo2 from '@/assets/home/aboutus/Frame49@2x.jpg';
import photo3 from '@/assets/home/aboutus/Frame50@2x.jpg';
import photo4 from '@/assets/home/aboutus/Frame51@2x.jpg';
import photo5 from '@/assets/home/aboutus/Frame52@2x.jpg';
import './index.css';
const photoList = [photo1, photo2, photo3, photo4, photo5];
const Aboutus = () => {
  const homeTwoRef = useRef(null);
  const logoRef = useRef(null);
  const [twoTopHeight, setTwoTopHeight] = useState(0);
  const [logoTopHeight, setLogoTopHeight] = useState(0);
  const [animate, setAnimate] = useState(true);
  const [logoAnimate, setLogoAnimate] = useState(false);

  const isAndroid = () => {
    const u = navigator.userAgent;

    if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    document.title = '鸿凯瑞达-关于我们';
    // const { top } = homeTwoRef.current.getBoundingClientRect();
    // setTwoTopHeight(top);
    // const logoRefValus = logoRef.current.getBoundingClientRect();
    // setLogoTopHeight(logoRefValus.top);
  }, []);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollDistance = window.scrollY;

  //     if (scrollDistance >= twoTopHeight) {
  //       // 滚动距离达到了指定值，设置状态以触发动画
  //       setAnimate(true);
  //     } else {
  //       // 如果滚动距离小于阈值，重置状态
  //       setAnimate(false);
  //     }

  //     if (scrollDistance >= logoTopHeight) {
  //       setLogoAnimate(true);
  //     }
  //   };

  //   // 添加滚动事件监听器
  //   window.addEventListener('scroll', handleScroll);

  //   // 组件卸载时移除监听器
  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);
  return (
    <div>
      <div className="home-one">
        <Header pageName="aboutus" />
        <div className="m-about-bg-top-img">
          <div className="en-text-m">
            Xi'an Hongkairuida Intelligence Electronic Technology Co.,Ltd
          </div>
          <div className="imgs-text-m">西安鸿凯瑞达智能电子科技有限公司</div>
        </div>
      </div>
      <div className="about-bg-two-img-m">
        <div className="about-bg-two-img-m-title">智驭安全·护航未来</div>
        <div className="about-bg-two-img-m-desc-one">
          西安鸿凯瑞达智能电子科技有限公司，致力于智能电子产品的研发。目前我们已开发了多款基于NB/LORA/4G/NFC/zigbee/蓝牙/超声波等的智能电子产品。最新研发的专利产品“智能报警施封锁”，颠覆了传统施封锁的使用机制，实现了重复使用、智能报警、多级管理等诸多创新功能，可应用到石油石化、电力通信等多个领域。
        </div>
        <div className="about-bg-two-img-m-desc-two">
          公司团队拥有丰富的产业背景和研发经验同时也和多所国内一流大学科研交流合作，利用先进的技术和创新的思维，为客户提供高品质、高性能的产品。
        </div>
      </div>
      <div className="about-bg-three-img-m">
        <div className="about-bg-three-img-m-en-title">Faith in Innovation</div>
        <div className="about-bg-three-img-m-title">创新成就信仰</div>
        <div className="about-bg-three-img-m-title-line"></div>

        <div className="about-bg-three-img-m-btm-text">
          我们秉承“客户至上、追求卓越”的公司宗旨，本着“开拓、拼搏、求实、创新”的企业精神，“以诚信为本、以质量求生存、以创新为动力，为客户创造价值”的企业经营理念为客户服务。
        </div>

        <div className="about-bg-three-img-m-three-quan">
          {/* <div
            className="about-bg-three-img-m-left-content"
            style={!isAndroid() ? { marginLeft: '16%', marginTop: '15%' } : {}}
          >
            <div className="about-bg-three-img-m-cn-name">革新</div>
            <div className="about-bg-three-img-m-en-name">INNOVATE</div>
          </div>
          <div
            className="about-bg-three-img-m-center-content"
            style={!isAndroid() ? { marginLeft: '0%' } : {}}
          >
            <div className="about-bg-three-img-m-cn-name">智创</div>
            <div className="about-bg-three-img-m-en-name">INGENUITY</div>
          </div>
          <div
            className="about-bg-three-img-m-right-content"
            style={!isAndroid() ? { marginRight: '16%', marginTop: '15%' } : {}}
          >
            <div className="about-bg-three-img-m-cn-name">绿色</div>
            <div className="about-bg-three-img-m-en-name">GREENER</div>
          </div> */}
        </div>

        <div className="about-bg-three-img-m-en-title">
          Honors reflect excellence
        </div>
        <div className="about-bg-three-img-m-title">荣誉见证卓越</div>
        <div className="about-bg-three-img-m-title-line"></div>

        <div className="about-bg-three-img-m-btm-text">
          通过客户导向的核心理念、追求卓越的战略追求、创新驱动的发展战略以及诚信与质量并重的战略基石，共同构建起公司独特的竞争优势与可持续发展能力为客户创造更大价值，实现企业的长期繁荣与社会责任的共同担当。{' '}
        </div>

        <div className="about-bg-three-img-m-h-card" style={{ marginTop: 30 }}>
          <Image src={OneRImg} className="about-bg-three-img-m-h-zhengshuimg" />
          <div className="about-bg-three-img-m-bt-ctnss">
            <div className="about-bg-three-img-m-top-title">
              一种智能无线报警施封锁
            </div>
            <div style={{ marginTop: 6 }}>
              <img src={sanimg} className="about-bg-three-img-m-sanimg" />
              <span className="about-bg-three-img-m-zlihao">
                专利号：ZL 202121275780.2
              </span>
            </div>
          </div>
        </div>

        <div className="about-bg-three-img-m-h-card">
          <Image src={rtwoimg} className="about-bg-three-img-m-h-zhengshuimg" />
          <div className="about-bg-three-img-m-bt-ctnss">
            <div className="about-bg-three-img-m-top-title">
              一种机械设备安全距离无线报警装置
            </div>
            <div style={{ marginTop: 6 }}>
              <img src={sanimg} className="about-bg-three-img-m-sanimg" />
              <span className="about-bg-three-img-m-zlihao">
                专利号：ZL 202121428038.0
              </span>
            </div>
          </div>
        </div>

        <div className="about-bg-three-img-m-h-card-two">
          <div className="about-bg-three-img-m-title-ctn">
            <div className="about-bg-three-img-m-en-title">
              Certificates and reports
            </div>
            <div className="about-bg-three-img-m-cn-title">各类证书报告</div>
          </div>
          <div className="about-bg-three-img-m-baogao-view">
            <Image
              src={bg1img}
              className="about-bg-three-img-m-h-zhengshuimg"
            />
            <Image
              src={bg2img}
              className="about-bg-three-img-m-h-zhengshuimg"
            />
            <Image
              src={bg3img}
              className="about-bg-three-img-m-h-zhengshuimg"
            />
            <Image
              src={bg4img}
              className="about-bg-three-img-m-h-zhengshuimg"
            />
            <Image
              src={bg5img}
              className="about-bg-three-img-m-h-zhengshuimg"
            />
            <Image
              src={bg6img}
              className="about-bg-three-img-m-h-zhengshuimg"
            />
            <Image
              src={bg7img}
              className="about-bg-three-img-m-h-zhengshuimg"
            />
            <Image
              src={bg8img}
              className="about-bg-three-img-m-h-zhengshuimg"
            />
          </div>
        </div>

        <div className="about-bg-four-m-en-title" style={{ marginTop: 60 }}>
          Innovation Leads to Shared Success
        </div>
        <div className="about-bg-four-m-title">智创共赢未来</div>
        <div className="about-bg-four-m-title-line"></div>

        <div class="m-wrapper">
          {photoList.map((reward, index) => (
            <img
              src={reward}
              className="m-item"
              style={{ '--count': index + 1 }}
            />
          ))}
        </div>
      </div>
      <div className="about-bg-four-m">
        <div className="about-bg-four-m-en-title">Our goals</div>
        <div className="about-bg-four-m-title">我们的目标</div>
        <div className="about-bg-four-m-title-line"></div>

        <div className="about-bg-four-m-border-div">
          <div className="about-bg-four-m-center-text">
            让每个人轻松开启数字新可能
          </div>
          <div className="about-bg-four-m-lefttop">脚踏实地</div>
          <div className="about-bg-four-m-righttop">坚毅敬业</div>
          <div className="about-bg-four-m-leftbottom">求真务实</div>
          <div className="about-bg-four-m-rightbottom">开拓创新</div>
        </div>
      </div>
      <DownloadBtn />
      <Footer />
    </div>
  );
};

export default Aboutus;
